import React from 'react';

import { navigate } from 'gatsby';

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY;
if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `);
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function RequestEstimateForm(props) {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <form
      name="Request Estimate"
      method="POST"
      data-netlify="true"
      netlify="true"
      netlify-honeypot="a_phone"
      onSubmit={handleSubmit}
      action="/thank-you"
      className="max-w-6xl m-auto"
    >
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      <div className="form-group hidden">
        <label htmlFor="a_phone">Don’t fill this out if you're human: </label>{' '}
        <input
          type="text"
          name="a_phone"
          style={{ display: 'none' }}
          tabIndex="-1"
          autoComplete="off"
        />
      </div>
      <div className="flex flex-wrap">
        <div className="form-group has-danger mb-3 w-full md:pr-3 md:w-1/2">
          <label
            htmlFor="nameBox"
            className="block text-base font-medium text-gray-700"
          >
            Name
          </label>{' '}
          <div class="mt-1">
            <input
              id="nameBox"
              name="name"
              type="text"
              onChange={handleChange}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="form-group has-danger mb-3 w-full md:pl-3 md:w-1/2">
          <label htmlFor="emailBox" className="block font-medium text-gray-700">
            Email
          </label>{' '}
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </div>
            <input
              type="text"
              name="email"
              id="emailBox"
              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 border-gray-300 rounded-md"
              placeholder="you@example.com"
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label
          htmlFor="textAreaInput"
          className="block font-medium text-gray-700"
        >
          What would you like us to estimate?
        </label>
        <div className="mt-1">
          <textarea
            id="textAreaInput"
            name="request"
            rows="8"
            onChange={handleChange}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md"
          ></textarea>
        </div>
      </div>
      <div class="pt-3">
        <div class="flex justify-end">
          <button
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}
